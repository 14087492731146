@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.tutorial {
  &__wrapper{
    width: 80%;
    height: 100%;
    margin: 4rem auto 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__header-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__back-link {
    @include back-link;
    margin: auto .75rem auto auto;
    cursor: pointer;
  }
  &__header{
    @include page-title;
    flex-basis: 100%;
    margin: .5rem 0;
  }
  &__description {
    @include regular-text;
    flex-basis: 100%;
    margin: .5rem 0;
    padding-bottom: 1rem;
  }
  &__video {
    width: 85%;
    flex-basis: 100%;
  }
}

@media (max-width: $medium-screen) {
  .tutorial {
    &__video {
      width: 100%;
    }
  }
}
