@import '../../../assets/css/variables.scss';
@import '../../../assets/css/mixins.scss';
@import '../inputs.scss';

.shared-inputs{
  &__calendar {
    border: none;
    border-bottom: 1px solid $dark-gray;
    height: 1.5rem;
    padding: .25rem;
    margin-right: .5rem;
    margin-left: .5rem;
    width: 8rem;
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-bottom: 2px solid $primary;
    }
  }
  &__calendar-popper {
    z-index: 40;
  }
}