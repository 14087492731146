@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.horizontal-scroll {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  display: inline-block;
  &__indicator-dots {
    font-size: 8px;
    margin: 0 .75rem;
    flex-grow: 1;
    color: $light-gray;
    &_dark {
      color: $dark-gray;
    }
  }
  &__arrows {
    position: relative;
    top: 3px;
    &_left {
      margin-right: .75rem;
    }
    &_right {
      margin-right: .75rem;
    }
  }
}