@import './../../../assets/css/variables.scss';
@import './../../../assets/css/mixins.scss';
@import './../../../assets/css/shared.scss';

.new-user-invitation {
  display: flex;
  flex-direction: column;
  &__header {
    text-align: center;
  }
  &__logo {
    height: 144px;
    width: auto;
  }
  &__submit-btn {
    @include regular-text;
    padding: .5rem 1.5rem !important; 
    width: auto;
    display: block;
    margin: 1.5rem auto 0 auto;
  }
  &--icon{
    font-size: 6rem;
    margin: 0 auto;
    display: block;
    &_success {
      color: $primary;
    }
    &_error {
      color: $warning;
    }
  }
  &__header-text {
    @include heading-2;
    margin-top: 1.25rem;
    &--secondary {
      @include regular-text;
      margin-bottom: 3rem;
      line-height: 1.5;
    }
    &--tiny_margin {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: $medium-screen) {
}