@import '../../assets/css/variables.scss';
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';
.dropdown-select {
  &__wrapper {
    position: relative;
    display: inline-block;
    padding-bottom: .25rem;
    width: 100%;
    select {
      background-color: $white;
      width: 100%;
      cursor: pointer;
      border: 0;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-bottom: 2px solid $light-gray;
      line-height: 1.5;
      height: 1.75rem;
      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
        border-bottom: 2px solid $primary;
      }
      &:disabled {
        cursor:not-allowed;
        background-color: transparent;
      }
    }
  }
  &__sort-down {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-block;
    right: 3px;
    pointer-events: none;
    &_disabled {
      color: $light-gray;
    }
  }
}