@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.mobile-nav {
  display: none;
}

@media (max-width: $medium-screen) {
  .mobile-nav {
    min-height: 32px;
    width: 100%;
    display: flex;
    background-color: $primary-dark;
    padding: 0 $xsm;
    margin-bottom: 2rem;
    &__link-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: stretch;
      &__link-item {
        flex-grow: 1;
        display: flex;
      }
      &__link {
        background-color: transparent;
        border: 0;
        outline:none;
        color: white;
        font-size: $sm;
        &_active {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
