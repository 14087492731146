@import '../../../assets/css/variables.scss';
@import '../../../assets/css/mixins.scss';

.show-hide-password {
  display: inline-block;
  position: relative;
  &__button {
    position: absolute;
    right : 1.25rem;
    display: inline-block;
    border: none;
    cursor: pointer;
    background-color: transparent;
    top: 0;
    bottom: 0;
    margin: auto 0;
    &-icon {
      font-size: 1rem;
    }
  }
  
}
