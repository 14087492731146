// colors
$primary: #33ca80;
$primary-dark: #0a3936;
$primary-light: #d7f7d8;
$secondary: #ffe66c;
$tertiary: #0b51ad;
$alert: #ff8d00;
$warning: #f93b59;
$warning-light: #fde3e7;
$dark-gray: #131514;
$med-dark-gray: #434a54;
$med-gray: #6a7789;
$light-gray: #e7e9ed;
$mint-gray: #f4f9f6;
$white: #fff;

// text sizes
$xsm:  .75rem;
$sm: .875rem;
$reg: 1rem;
$lg: 1.25rem;
$xl: 1.5rem;
$xxl: 2.125rem;

$bold: 700;
$light-text: 300;

// breakpoints
$small-screen: 640px;
$medium-screen: 768px;
$large-screen: 1024px;