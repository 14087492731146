@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';
@import '../../assets/css/buttons.scss';

@mixin decline-text {
  color: $med-gray;
}
.new-requests {
  @include regular-text;
  @include refresh-icon;
  @include refresh-loader-icon;
  @include error-text-element;
  @include centered-loader;

  &__service-flag {
    color: $warning;
    &-text {
      @include font;
      font-size: $sm;
      color: $med-gray;
      padding-top: .5rem;
      font-style: italic;
    }
    & svg {
      margin-right: .5rem;
    }
  }
  &__loader {
    &_inline {
      display: inline-block
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  &__header {
    @include heading-2;
    margin-bottom: .5rem;
  }
  &__section {
    @include app-wrapper;
  }
  &__item-exit {
    opacity: 1;
  }
  &__item-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-out
  }
  &__line-item {
    width: 100%;
    padding: .75rem;
    display: flex;
    &__wrapper {
      margin-bottom: 1rem;
      border: 1px solid $med-dark-gray;
    }
  }
  &__icon-wrapper {
    margin-right: .5rem;
  }
  &__icon {
    cursor: pointer;
    &_decline {
      @include decline-text;
    }
  }
  &__request-type-header {
    @include bold-text;
    &_decline {
      @include decline-text;
    }
  }
  &__date {
    @include small-text;
    color: $med-gray;
    margin: .5rem 0;
    &_decline {
      @include decline-text;
    }
    &_today {
      color: $alert;
    }
    &_before-today {
      color: $warning;
    }
  }
  &__address {
    @include small-text;
    margin-bottom: 1rem;
    &_decline {
      @include decline-text;
    }
  }
  &__cancel-btn {
    color: $warning;
    font-size: $xsm;
    margin-right: .75rem;
    &_details {
      font-size: $reg;
    }
  }
  &__confirm-btn {
    font-size: $xsm;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    &_details {
      font-size: $reg;
    }
  }
  &__verify-checkmark {
    margin-right: .5rem;
  }
  &__decline-form {
    width: 100%;
    &_details {
      margin-top: -2rem;
      &_form {
        width: 50%;
        &_mr {
          margin-right: 1.5rem;
        }
      }
    }
    &_details_wrapper {
      display: flex;
      flex-direction: row;
      margin: 1rem 0;
    }
    &-text-area {
      @include small-text;
      @include text-area;
      width: 100%;
      margin-bottom: 1rem;
      &_mt {
        margin-top: -.25rem;
      }
    }
    &-label {
      @include small-text;
      color: $med-dark-gray;
      margin-bottom: 1.25rem;
      margin-top: 1.5rem;
      display: block;
      &-details {
        margin-bottom: .25rem;
      }
    }
    &-submit {
      font-size: $xsm;
      margin-right: .75rem;
      &_details {
        font-size: $reg;
      }
    }
    &-cancel {
      font-size: $xsm;
      &_details {
        font-size: $reg;
      }
    }
  }
  &__confirm-btn-wrapper {
    padding: 1rem 0;
  }
  &__decline-input {
    &_error{
      background-color: $warning;
    }
  }
  &__alert-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: $medium-screen) {
  .new-requests {
    &__wrapper {
      margin: 0 .75rem;
      width: auto;
    }
    &__section {
      padding: .75rem;
    }
  }
}