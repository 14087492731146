@import './variables.scss';
@import './mixins.scss';

@mixin button-copy-white {
  @include regular-text;
  color: $white;
}

@mixin btn-state ($bg-color, $disabled-text-color) {
  &:hover {
    background-color: darken($bg-color, 10%);
  }
  &:disabled {
    background-color: rgba($bg-color, .5);
    cursor: default;
    color: $disabled-text-color;
    box-shadow: none;
  }
}

.button {
  &--action {
    @include link-text;
    display: inline-block;
    border: none;
    cursor: pointer;
    padding: .25rem .5rem;
    background-color: transparent;
    &__inline {
      display: inline;
      padding: 0;
    }
  }
  &__submit {
    border: none;
    @include small-border-radius;
    cursor: pointer;
    @include small-text;
    box-shadow: 0px 1px 4px $med-gray;
    padding: .25rem .5rem;
    @include element-transition;
    color: $dark-gray;

    &--primary {
      &_green {
        background-color: $primary;
        @include btn-state($primary, $white);
      }

      &_orange {
        background-color: $alert;
        color: $white;
        @include btn-state($alert, $white);
      }

      &_dark {
        background-color: $primary-dark;
        color: $white;
        @include btn-state($primary-dark, $white);
      }

      &_mint {
        background-color: $primary-light;
        @include btn-state($primary-light, $dark-gray);
      }
      &_white {
        background-color: $white;
        @include btn-state($light-gray, $med-gray);
      }
      &_warning {
        background-color: $warning;
        color: $white;
        @include btn-state($warning, $white);
      }
      &_warning-light {
        background-color: $warning-light;
        color: $white;
        @include btn-state($warning-light, $warning);
      }
    }

    // &_secondary {
      
    // }
  }
}