@import '../../assets/css/reset.scss';
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

body {
  background-color: $light-gray;
}

.empty-canvas {
  &_centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  &_theme_dl {
    background-color: $light-gray;
    @include regular-text;
  }
  &__footer {
    @include small-text;
    text-align: center;
    color: $med-gray;
    margin-top: 2rem;
  }
}

@media (max-width: $medium-screen) {
  .empty-canvas {
    &_centered {
      display: block;
    }
  }
}