@import '../../../assets/css/variables.scss';
@import '../../../assets/css/mixins.scss';
@import '../inputs.scss';

.shared-inputs {
  &__checkbox {
    input[type="checkbox"] {
      display: none;
      opacity: 0;
      z-index: -1;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none
    }
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: $white;
    display: inline-block;
    border: 2px solid $med-dark-gray;
    @include small-border-radius;
    &_small {
      margin-bottom: -.25rem;
      width: 18px;
      height: 17px;
    }
    &_large {
      width: 24px;
      height: 23px;
    }
    &_checked {
      background-color: $primary;
      border-color: $primary;
      &_must-call {
        background-color: $warning;
        border-color: $warning;
      }
    }
    &_hidden {
      display: none;
      opacity: 0;
      z-index: -1;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none
    }
  }
  &__checkbox-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    @include element-transition;
  }
  &__checkbox-icon {
    position: absolute;
    color: $white;
  }
  &__must-call-icon {
    color: $white;
  }
  &__no-must-call-icon {
    color: $dark-gray;
  }
}