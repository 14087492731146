@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.mobile-menu-container {
  &__menu-icon {
    color: $white;
    font-size: $xl;
    cursor: pointer;
    margin-right: .5rem;
  }
  &__menu {
    position: fixed;
    height: 100vh;
    width: 85vw;
    background-color: $white;
    right: 0;
    top: 0;
    z-index: 501;
    box-shadow: 0px 1px 4px $med-gray;
    &__user {
      background-color: $primary-dark;
      color: $white;
      padding: 1.5rem;
    }
    &__list {
      padding: 1.25rem 0;
      font-size: $lg;
      &__item {
        padding: .75rem 1.25rem;
        cursor: pointer;
        &:hover {
          background-color: darken($white, 10%);
        }
        &_icon {
          margin-right: .75rem
        }
        &_link {
          color: $dark-gray;
          padding: .75rem 1.25rem;
          display: block;
          width: 100%;
          cursor: pointer;
          &:hover {
            background-color: darken($white, 10%);
          }
        }
      }
    }
  }
  &__logout-modal {
    width: 85vw;
    padding: .75rem 0;
    &__title {
      @include heading-2;
      margin-bottom: .5rem;
    }
    &__confirmation-msg {
      margin: .5rem 0 1.5rem 0;
      color: $med-gray;
    }
    &__buttons {
      & button {
        padding: .25rem 2.5rem;
      }
      & button:first-child {
        margin-right: 1rem;
      }
    }
  }
  &__menu-transition-enter {
    width: 0;
  }
  &__menu-transition-enter-active {
    width: 85vw;
    transition: width 300ms ease-in;
  }
  &__menu-transition-exit {
    width: 85vw;
  }
  &__menu-transition-exit-active {
    width: 0;
    transition: width 300ms ease-out;
  }
}