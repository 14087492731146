@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.error-page {
  @include error-text-element;
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1.5rem;
  }
  &__icon {
    font-size: 3.75rem;
    line-height: 1;
    color: $warning;
    margin-top: 4rem;
    // text-6xl text-red mt-16
  }
  &__header{
    @include heading-2;
    margin: .5rem 0;
  }
}

