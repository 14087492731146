@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.alert {
  height: auto;
  width: 100%;
  margin: auto;
  &__content {
    display: flex;
  }
  &__item-enter {
    opacity: 0;
  }
  &__item-enter-active {
    opacity: .5;
    transition: opacity 800ms ease-in;
  }
  &__item-exit {
    opacity: 1;
  }
  &__item-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-out;
  }
  &_error {
    font-size: $xsm;
    color: $warning;
    font-weight: 500;
    background-color: $warning-light;
    border-left: 2px solid $warning;
    padding: .75rem;
    width: 100%;
    display: inline-block;
    &__content {
      display: flex;
      align-items: center;
    }
    &__icon {
      font-size: $lg;
      margin-right: .5rem;
    }
    &__text {
      position: relative;
      top: -2px;
    }
  }
  &_close {
    color: $dark-gray;
    cursor: pointer;
    margin-left: auto;
  }
}
