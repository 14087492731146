@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.availability {
  @include small-text;
  @include light-text;
  @include error-text-element;

  &__scroll-container {
    display: none;
  }

  & th, td {
    padding: .875rem 0;
  }
  &
  &__wrapper {
    width: 100%;
  }
  &__header {
    @include heading-2;
    margin-bottom: .5rem;
  }
  &__app {
    @include app-wrapper;
  }
  &__table {
    text-align: center;
    width: 100%;
  }
  &__table-header {
    border: 1px solid $med-gray;
    background-color: $mint-gray;
    &_border_right{
      border-right: 1px solid $med-gray;
    }
    &_border_left{
      border-left: 1px solid $med-gray;
    }
    &_title-row {
      border: none;
      background-color: none;
      @include bold-text;
      &__must-call-header {
        max-width: 90px;
      }
      &_days-out {
        input {
          max-width: 60px;
        }
      }
      &__must-call-icon {
        cursor: pointer;
        margin-left: 2px;
        color: $med-gray;
        position: relative;
      }
      @include tooltip-contents;
    }
  }
  &__cell-row {
    border: 1px solid $med-gray;
    &_border_right{
      border-right: 1px solid $med-gray;
    } 
    &_border_left{
      border-left: 1px solid $med-gray;
    }
    &_middle {
      vertical-align: middle;
    }
    &_bold {
      @include bold-text;
    }
    &_first-col {
      font-size: $reg;
      vertical-align: middle;
      &_text {
        margin: auto;
      }
    }
    &_days-out {
      input {
        max-width: 60px;
      }
    }
  }
  &__spinner {
    @include input-spinner-centered;
  }
}

@media (max-width: $medium-screen) {
  .availability {
    &__wrapper {
      margin-left: .75rem;
    }
    &__mobile-wrapper {
      position: relative;
    }
    &__scroll-container {
      display: flex;
      width: 100%;
      margin-left: 5.5rem;
      padding: .25rem;
    }
    &__scrollable-mobile {
      overflow-x: auto;
      overflow-y: visible;
      -ms-overflow-style: none;
      position: relative;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__app {
      padding: .75rem 0 .75rem .75rem;
      overflow: hidden;
      margin-right: 1px;
    }
    & td {
      border-bottom: 1px solid $med-gray;
    }
    &__table {
      width: 692px;
      border-collapse: separate;
      & td:first-child {
        position: -webkit-sticky;
        position:sticky;
        z-index: 500;
        left: 0;
        width: 90px;
        background-color: $white;
        height: 54px;
        border-left: 1px solid $med-gray;
      }
    }
    &__cell-row {
      & td:last-child {
        border-right: 1px solid $med-gray;
      }
    }
    &__table-header {
      & th {
        height: 43px;
        border-bottom: 1px solid $med-gray;
      }
      & th:last-child {
        border-right: 1px solid $med-gray;
      }
      & th:first-child {
        position: -webkit-sticky;
        position:sticky;
        z-index: 500;
        left: 0;
        background-color: $mint-gray;
        border: 1px solid $med-gray;
        border-top: none;
        width: 90px;
        height: 43px;
      }
      &_title-row {
        & th {
          padding-bottom: 0 !important;
          padding-top: 1.5rem !important;
        }
        & th:nth-child(6) {
          max-width: 175px;
        }
        &__must-call-header {
          width: 92px;
        }
        & th:first-child {
          position: -webkit-sticky;
          position:sticky;
          z-index: 500;
          left: 0;
          width: 90px;
          background-color: $white;
        }
        &__tooltip-content {
          width: 175px;
        }
        &__apply-all {
          & td:first-child {
            height: 53px;
            border-left: none;
            position: -webkit-sticky;
            position:sticky;
            z-index: 500;
            left: 0;
            background-color: $white;
          }
        }
      }
    }
  }
}