@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';
@import '../../assets/css/buttons.scss';

.scheduled-jobs {
  @include refresh-icon;
  @include refresh-loader-icon;
  @include error-text-element;
  @include centered-loader;
  &_mobile {
    display: none;
  }
  &__no-jobs{
    @include regular-text;
    text-align: center;
  }
  &__section {
    margin-bottom: 2rem;
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .5rem;
  }
  &__title {
    @include heading-2;
  }
  &__app {
    @include app-wrapper;
    max-height: 40vh;
    overflow-y: auto;
  }
  &__table {
    @include small-text;
    vertical-align: center;
    width: 100%;
    margin-top: .5rem;
    & th, td {
      padding: .5rem .25rem;
      vertical-align: middle;
    }
    & th:first-child, td:first-child {
      padding-left: .5rem;
    }
    & th:last-child, td:last-child {
      padding-right: .5rem;
    }
    & th {
      @include bold-text;
      text-align: left;

      &:first-child {
        width: 3%;
      }
      &:nth-child(2) {
        width: 40%;
      }
        
      &:nth-child(3), :nth-child(4){
        width: 20%
      }

      &:last-child {
        width: 20%;
      }
    }
    & tbody {
      & tr {
        border: 1px solid $med-gray;
      }
    }
  }
  &__info-icon {
    margin: 0 auto;
    cursor: pointer;
    &_disabled {
      cursor: default;
    }
  }
  &__service-row {
    &_disabled {
      color: $med-gray
    }
    &_error {
      td:nth-child(3),
      td:nth-child(5) {
        padding: 0;
      }
    }
  }
  &__verify-step {
    background-color: $light-gray;
    &_error {
      padding: 0;
    }
  }
  &__verify-step-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & button {
      font-size: $xsm;
    }
  }
  &__verify-text {
    @include xsmall-text;
    width: 50%;
    &_mark-all {
      flex-grow: 1;
      margin: auto;
    }
  }
  &__verify-step-cancel {
    @include xsmall-text;
    &_mark-all {
      margin-right: .75rem;
    }
  }
  &__mark-service-complete {
    text-align: right;
    & button {
      width: 100%;
      font-size: $xsm;
    }
    &_all {
      padding: .5rem 0;
      button {
        width: 20%;
      }
    }
  }
  &__verify-checkmark {
    margin-right: .5rem;
  }
  &__mark-complete-row {
    border: none !important;
    & td {
      padding: 0;
    }
  }
  &__mark-complete-cell {
    padding: 0 0 2rem 0 !important;
  }
  &__mark-complete-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: .5rem .25rem .5rem .5rem;
    &_verify {
      justify-content: stretch;
      background-color: $light-gray;
      border: 1px solid $light-gray;
      @include small-text;
    }
  }
  &__cancelled-row {
    color: $med-gray;
  }
  &__date-picker {
    @include regular-text;
    display: flex;
    flex-direction: row;
    align-items: center;
    & button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    & span {
      margin: 0 .5rem;
    }
  }
  &__details-button-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &__no-message {
      padding: 2rem 0 1rem 0;
    }
    &__verify-details {
      padding: 1rem 0 1rem 0;
      background-color: $light-gray;
      margin-left: -1rem;
      margin-bottom: -1rem;
      width: 110%;
      height: 110%;
      &__button-container {
        padding: .75rem 0 .5rem 0;
        &__verify-buttons {
          &_margin-right {
            margin-right: .75rem;
          }
        }
      }
    }
  }
}

@media (max-width: $medium-screen) {
  .scheduled-jobs {
    &__section {
      margin: 0 .75rem;
    }
    &__title {
      width: 100%;
    }
    &__date-picker {
      margin: 1rem auto .5rem auto;
      & button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 18px;
      }
      & span {
        margin: 0 .5rem;
        font-size: 18px;
      }
    }
    &__app {
      max-height: fit-content;
      padding: .75rem;
    }
    &__table {
      margin-top: 0;
      & thead {
        display: none;
      }
      & tbody {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        & tr {
          width: 100%;
          padding: .5rem;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 1rem;
          & td:first-child {
            order: 1;
            font-size: $reg;
          }
        }
      }
      &__row {
        &_address {
          order: 4;
          margin-left: 1.75rem;
          margin-top: -.25rem;
          width: 75%;
        }
        &_type {
          order: 2;
          font-size: $reg;
        }
        &_size {
          order: 3;
          font-size: $reg;
        }
        &_button {
          order: 5;
          margin-left: 1.75rem;
          & button {
            height: 26px;
            width: 104px;
          }
        }
      }
    }
    &__verify-step {
      order: 5;
      margin: .5rem -.5rem -.5rem -.5rem;
    }
    &__verify-step-wrapper {
      justify-content: normal;
      flex-wrap: wrap;
      padding: 0 .25rem;
      & :nth-child(2) {
        margin-left: .75rem;
      }
      & :last-child {
        margin-left: 1.75rem;
      }
      & button {
        height: 26px;
      }
    }
    &__verify-text {
      width: 100%;
      margin-bottom: .5rem;
      font-size: $xsm;
      margin-left: 1.75rem;
    }
    &__verify-step-cancel {
      order: 3;
    }
    &_mobile {
      display: inline;
    }
    &__mark-complete-row {
      justify-content: flex-end;
      margin-bottom: 0 !important
    }
    &__mark-complete-wrapper {
      & button {
        margin-top: -1rem;
        height: 26px;
      }
      &_verify {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: -1.5rem;
        justify-content: flex-end;
      }
    }
    &__verify-text_mark-all {
      display: flex;
      width: 100%;
      font-size: $xsm;
      margin-bottom: 2rem;
    }
    &__mark-complete-cell {
      margin-right: -.5rem !important;
      margin-left: -.5rem;
      padding-bottom: 0 !important;
    }
    &__details-button-container {
      &__verify-details {
        padding: 1rem;
      }
    }
  }
}