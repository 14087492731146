@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/buttons.scss';
@import './../../assets/css/shared.scss';

.route {
  &__error-btn {
    @include light-text;
    font-size: $reg;
    padding: 0;
  }
}