@import '../../../assets/css/variables.scss';
@import '../inputs.scss';

.shared-inputs {
  &__number {
    border: none;
    border-bottom: 1px solid $dark-gray;
    padding: .25rem;
    text-align: center;
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-bottom: 2px solid $primary;
    }
  }
}