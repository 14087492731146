@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.loader {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &_inline {
      display: inline-block;
    }
  }
  &__icon {
    @include input-spinner-centered;
  }
}