@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

@mixin top-position {
  top: 150%;
}

@mixin top-arrow {
  top: -10px;
  right: 0;
  transform: translateX(-50%);
}

.tooltip {
    position: relative;
    &__wrapper {
      position: absolute;
      z-index: 1000;
      width: auto;
      height: auto;
      background: $primary-light;
      box-shadow: 0px 1px 4px $med-gray;
      padding: 10px;

      &_top{
        &-right {
          @include top-position;
          right: -45px;
        }
        &-left {
          @include top-position;
          left: -45px;
        }
        &-center {
          @include top-position;
          left: auto;
          right: auto;
        }
      }
      
      &__arrow {
        width: 50px;
        height: 10px;
        position: absolute;
        overflow: hidden;

        &_top {
          &-right {
            @include top-arrow;
            right: 0;
          }
          &-left {
            @include top-arrow;
            left: 0;
          }
          &-center {
            @include top-arrow;
            left: 45%;
          }
        }
        
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: $primary-light;
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
          top: 100%;
          left: 50%;
          box-shadow: 0px 1px 4px $med-gray;
        }
      }
    }
  }