@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.details-container {
  max-width: 658px;
  &__details {
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
    padding-right: 1rem;
    color: $dark-gray;
    &__fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &__title {
        font-size: $xsm;
        color: $med-gray;
        margin-bottom: .25rem;
        width: 45%;
        &_address {
          width: 100%;
          font-size: $xsm;
          color: $med-gray;
          margin-bottom: .25rem;
        }
      }
      &__details {
        margin-bottom: 1rem;
        width: 45%;
        &_hidden {
          display: none;
        }
      }
      &__eta {
        padding-right: .5rem;
      }
      &__address {
        width: 100%;
      }
    }
    &__map-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 274px;
      height: 189px;
      &__map {
        &__map-image {
          border: 1px solid $light-gray;
          width: 274px;
          height: 189px;
          margin-left: 10px;
        }
        &__map-link {
          float: right;
          margin-top: .25px;
          font-size: $sm;
        }
      }
    }
  }
  &__map-link-mobile {
    display: none;
  }
  &__details-secondary {
    margin-top: .75rem;
    &__section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: $dark-gray;
      &_full {
        width: 100%;
        margin-bottom: 1rem;
        min-height: 2rem;
      }
      &_half {
        width: 55%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 2rem;
      }
      &__title {
        font-size: $xsm;
        color: $med-gray;
        margin-bottom: .25rem;
        width: 45%;
      }
      &__details {
        margin-bottom: 1rem;
        width: 45%;
      }
    }
  }
}

@media (max-width: $medium-screen) {
  .details-container {
    &__details {
      flex-wrap: wrap;
      &__map-container {
        height: 0;
        &__map {
          display: none;
        }
      }
      &__fields {
        width: 100%;
        order: 2;
      }
    }
    &__details-secondary {
      &__section {
        &_half {
          width: 100%;
        }
      }
    }
    &__map-link-mobile {
      display: block;
      margin-top: .25rem;
      font-size: $sm;
    }
  }
}