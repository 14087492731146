@import './variables.scss';

@mixin font {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 500;
}

// text and whatnot
@mixin page-title {
  @include font;
  font-weight: $light-text;
  font-size: $xxl;
  color: $dark-gray;
}

@mixin back-link {
  @include font;
  font-weight: $light-text;
  font-size: $xl;
  color: $dark-gray;
}

@mixin heading-2 {
  @include font;
  font-weight: $light-text;
  font-size: $xl;
  color: $dark-gray;
}

@mixin heading-3 {
  @include font;
  font-weight: $light-text;
  font-size: $lg;
  color: $dark-gray;
}

@mixin regular-text {
  @include font;
  font-size: $reg;
  color: $dark-gray;
}

@mixin small-text {
  @include font;
  font-size: $sm;
  color: $dark-gray;
}

@mixin xsmall-text {
  @include font;
  font-size: $xsm;
  color: $dark-gray;
}

@mixin link-text {
  color: $tertiary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@mixin light-text {
  @include font;
  font-weight: $light-text;
}

@mixin bold-text {
  @include font;
  font-weight: $bold;
}

@mixin error-text-element {
  &__error-text {
    @include light-text;
    text-align: center;
  }
}

@mixin app-wrapper {
  @include app-shadow;
  background-color: $white;
  padding: 1rem 1.5rem 4rem 1.5rem;
}

// Pages that look like auth.dispatcher.com
@mixin auth-like-wrapper {
  @include app-shadow;
  width: 490px;
  margin: 0 auto;
  padding: 2rem;
  background-color: $white;
}

@mixin auth-like-wrapper-mobile {
  width: auto;
  margin: .5rem;
  padding: 1rem;
}

@mixin auth-like-section {

}

// shadows!
@mixin app-shadow {
  box-shadow: 0px 1px 4px $med-gray;
  border-radius: .125rem;
}

// input spinners 
@mixin input-spinner {
  height: 1.5rem;
  width: 1.5rem;
}

@mixin input-spinner-centered {
  @include input-spinner();
  margin: 0 auto;
}

// border radius
@mixin small-border-radius {
  border-radius: .125rem;
}

// transition
@mixin element-transition {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

// refresh icon
@mixin refresh-icon {
  &__refresh-icon {
    cursor: pointer;
    border: none;
    top: -1px;
    background-color: transparent;
    font-size: $reg;
    margin-left: .5rem;
    position:relative;
    top: -2px;
    &:disabled {
      cursor: default;
    }
  }
}

@mixin centered-loader{
  &__loader {
    margin: 0 auto;
  }
}

@mixin input-field {
  border: none;
  border-bottom: 1px solid $med-gray;
  padding: .5rem;
  outline: 0;
}

@mixin text-area {
  line-height: normal;
  overflow-y: hidden;
  min-height: 3rem;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid $light-gray;
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-bottom: 2px solid $primary;
  }
}

@mixin refresh-loader-icon { 
  &__refresh-loader {
    & .loader__icon {
      height: 1rem;
      width: 1rem;
    }
  }
}

@mixin tooltip-contents {
  &__tooltip-content {
    @include regular-text;
    @include xsmall-text;
    text-align: left;
    width: 229px;

    &_details {
      margin-bottom: 16px;
    }
    &_details-note {
      @include bold-text;
      &_text-warning {
        color: $warning;
        margin-right: 2px;
      }
    }
  }
}
