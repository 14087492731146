@import './variables.scss';
@import './mixins.scss';

.auth-like-wrapper {
  @include app-shadow;
  width: 490px;
  margin: 0 auto;
  padding: 2rem;
  background-color: $white;
}
@media (max-width: $medium-screen) {
  .auth-like-wrapper{
    width: auto;
    margin: .5rem;
    padding: 1rem;
  }
}