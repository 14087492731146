
@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 1000;
  &__main {
    background-color: $white;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    max-width: 70%;
    top: 10vh;
    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__header {
        display: flex;
        &__title {
          font-size: $xl;
          margin-bottom: .75rem;
        }
        &__print {
          font-size: $xsm;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: .25rem;
          position: relative;
          top: -5px;
          cursor: pointer;
          &__icon-print {
            color: $white;
          }
          &__icon-circle {
            color: $med-gray;
          }
        }
        &__close {
          color: $dark-gray;
          cursor: pointer;
          font-size: $reg;
        }
      }
    }
  }
}

@media (max-width: $medium-screen) {
  .modal {
    &__main {
      max-width: $medium-screen;
      top: 12vh;
    }
  }  
}
