@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.help-container {
  position: absolute;
  right: 20px;
  top: 85%;
  width: fit-content;
  box-shadow: 0px 1px 4px $med-gray;
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &__help {
    margin-left: auto;
    display: flex;
    height: 100%;
    position: relative;
    &__button {
      @include regular-text;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: $white;
      padding: .75rem 1rem;
      margin: 0 1.25rem 0 0;
      cursor: pointer;
      &:hover {
        background-color: $dark-gray;
      }
    }
  }

  &__item {
    white-space: nowrap;
    border-bottom: 1px solid $light-gray;
    &:hover {
      background-color: darken($white, 10%);
    }
    &__quickstart-link {
      padding: .75rem;
      &__text {
        color: $dark-gray;
      }
      &__icon {
        margin-left: 1.5rem;
        position: relative;
        bottom: 1px;
        color: $dark-gray;
      }
    }
    &__contact {
      width: 100%;
      padding: .75rem;
      cursor: pointer;
      height: 100%;
      @include regular-text;
    }
    &__tutorial {
      display: block;
      width: 100%;
      padding: .75rem;
      cursor: pointer;
      height: 100%;
      @include regular-text;
    }
  }

  &__contact-container {
    padding: 0 .75rem 1.5rem .75rem;
    text-align: center;
    width: 30vw;
    &__title {
      margin-bottom: .25rem;
      @include heading-2;
    }
    &__text {
      margin-bottom: 2rem;
      @include small-text;
      font-weight: 300;
    }
    &__input {
      padding: .78rem .5rem;
      border: none;
      background: $light-gray;
      min-width: 230px;
      cursor: default;
      &:focus {
        outline: none;
      }     
    }
    &__copy-button {
      @include small-text;
      position: relative;
      top: 1px;
      border: none;
      cursor: pointer;
      &_copy {
        padding: .75rem;
      }
      &_copied {
        padding: .75rem 2.77rem;
      }
      &_icon {
        margin-left: .5rem;
      }
    }
  }
}

@media (max-width: $medium-screen) {
  .help-container {
    &__contact-container {
      width: 85vw;
      padding: .5rem 0 0 0;
      &__title {
        @include heading-2;
        padding: .5rem .75rem 0 .75rem;
      }
      &__text {
        @include regular-text;
        font-weight: 300;
      }
      &__input {
        width: 85vw;
      }
      &__copy-button {
        margin-bottom: .75rem;
        top: 0;
        width: 100%;
      }
    }
    &__help {
      display: none;
    }
  }
}

