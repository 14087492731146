@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/buttons.scss';
@import './../../assets/css/shared.scss';

.invite-user-form {
  @include tooltip-contents;
  @include error-text-element;
  display: flex;
  flex-direction: column;

  & label {
    @include small-text;
    @include light-text;
    color: $med-dark-gray;
    display: block;
    margin-bottom: 1rem;
  }
  &--icon {
    &_remove_email {
      font-size: 1rem;
      transform: rotate(45deg);
      color: $dark-gray;
      padding: 0;
    }
  }
  &__input{
    &--error {
      border-bottom: 1px solid $warning !important;
    }
    &--secondary {
      & input {
        width: 95% !important;
      }
      & .button--action {
        padding: 0 0 0 .25rem;
      }
    }
  }
  & input {
    @include input-field;
    @include regular-text;
    width: 100%;
    margin-top:.25rem;
    &:read-only {
      cursor: default;
    }
  }
  &__submit-btn {
    @include regular-text;
    padding-top: .75rem !important; 
    padding-bottom: .75rem !important; 
    width: 90%;
    display: block;
    margin: 1.5rem auto 0 auto;
    &--shortened {
      width: 80%;
      text-align: center;
      margin-top: 3rem;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__add-btn {
    margin: 0 0 0 auto;
    width: auto;
    &:disabled {
      text-decoration: none;
      cursor: default;
    }
  }
}

@media (max-width: $medium-screen) {
  .invite-user-form {
    &__input{
      &--secondary {
        & .button--action {
          padding: 0;
        }
      }
    }
  }
}
