@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.shared-inputs {
  &__spinner {
    @include input-spinner;
    &_centered {
      margin: 0 auto;
    }
  }
  &__hidden-label {
    display: none;
  }
}
