@import './reset.scss';
@import './variables.scss';
@import './mixins.scss';

body {
  @include font;
}

#root {
  width: 100%;
  height: 100%;
}

.page{
  &_theme_dl {
    width: 100%;
    height: 100%;
    background-color: $light-gray;
  }
}

a {
  @include link-text;
}

// h1 {}
// h2 {}
// h3 {}