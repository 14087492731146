@import '../../assets/css/reset.scss';
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.app-layout {
  @include error-text-element;
  @include centered-loader;
  &__error-text {
    max-width: 40%;
    margin-top: .5rem;
  }

  &_theme_dl {
    background-color: $light-gray;
    @include regular-text;
  }
  &__footer {
    padding: 4rem 0;
    text-align: center;
    @include small-text;
    &_theme_dl {
      color: $med-gray
    }
  }
}

@media (max-width: $medium-screen) {
  .app-layout {
    &__error-text {
      max-width: 100%;
    }
  }
}
