@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';

.user-creation {
  @include auth-like-wrapper;

  &__header {
    text-align: center;
  }
  &__logo {
    height: 144px;
    width: auto;
  }
  &__header-text {
    @include heading-2;
    margin-top: 1rem;
    &_secondary {
      @include regular-text;
      margin-top: .5rem;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: $medium-screen) {
  .user-creation{
    @include auth-like-wrapper-mobile;
  }
}