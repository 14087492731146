@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins.scss';

.dashboard {
  &__wrapper{
    width: 80%;
    height: 100%;
    margin: 4rem auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__left-column{
    width: 30%;
  }
  &__right-column {
    width: 66%;
    display: flex;
    flex-direction: column;
  }

@media (max-width: $medium-screen) {
    &__wrapper {
      margin: 0 auto 0 auto;
      width: 100%;
      flex-wrap: wrap;
    }
    &__left-column{
      width: 100%;
      margin-bottom: 1rem;
    }
    &__right-column {
      &:first-child, &:last-child {
        width: 100%;
      }
    }
  }
}
