@import './../../assets/css/variables.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/buttons.scss';

.user-creation-form {
  @include tooltip-contents;
  @include error-text-element;

  &__tooltip{
    margin-left: .25rem;
    & ul {
      margin: .5rem 0 0 .5rem;
      list-style-type: circle;
      & li {
        margin-left: .25rem;
      }
    }
    &-content {
      width: 250px;
    }
  }
  &_error {
    margin-top: 1rem;
  }
  & label {
    @include small-text;
    @include light-text;
    color: $med-dark-gray;
    display: block;
    margin-bottom: 1rem;
  }
  &__input-wrapper {
    display: block !important;
  }
  &__input{
    &_error {
      border: 1px solid $warning !important;
    }
  }
  & input {
    @include input-field;
    @include regular-text;
    width: 100%;
    margin-top:.25rem;
    &:read-only {
      cursor: default;
    }
  }
  &__error-box {
    background-color: $warning-light;
    border-left:1px solid $warning;
    display: flex;
    flex-direction: row;
    
  }
  &__submit-btn {
    @include regular-text;
    padding-top: .75rem  !important; 
    padding-bottom: .75rem  !important; 
    width: 90%;
    display: block;
    margin: 1.5rem auto 0 auto;
    &--shortened {
      width: 80%;
      text-align: center;
      margin-top: 3rem;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
